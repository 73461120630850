import { listType } from '../utils/AppState';
import { countrys } from '../utils/Area';
import RadioGroup from '../components/RadioGroup';
export default {
    data() {
        return {
            page: 1,
            pageSize: 20,
            totalPages: 0,
            totalElements: 0,
            loading: false,
            list: [],
            sort: 'COMPOSITE',
            empty: false,
            defaultProps: {
                children: 'children',
                label: 'label'
            },
            showType: 'gride',
            searchKey: '',
            search: '',
            chooseCountry: '',
            continent: '',
            formType: {},
            listTypes: [...listType].map(item => {
                return {
                    value: item[0],
                    label: item[1].name,
                    icon: item[1].icon
                };
            }),
            httpType: 'post',
            pushList: false, //是否往list后面push
            finish: false
        };
    },
    computed: {
        pageQuery() {
            let _routeQuery = this.routeQuery || {};
            return {
                ..._routeQuery,
                showType: this.showType
            };
        },
        countrys() {
            return countrys().map(item => {
                return {
                    id: item.value,
                    label: this.$i18n.t(item.label)
                };
            });
        }
    },
    mounted() {
        // console.log(this.$i18n.messages);
        if (this.$route.query.productSort) {
            this.sort = this.$route.query.productSort;
        }

        if (this.$route.query.search) {
            this.search = this.$route.query.search;
            this.searchKey = this.$route.query.search;
            if (this.setSearch) {
                this.setSearch(this.search);
            }
        }

        if (this.$route.query.size) {
            this.size = this.$route.query.size;
        }

        if (this.$route.query.page) {
            this.page = Number(this.$route.query.page) > 1 ? Number(this.$route.query.page) : 1;
        }
    },
    methods: {
        getInfo(list) {
            return list.map(item => {
                if (item.children) {
                    return {
                        id: item.value,
                        label: this.$i18n.t(item.label),
                        children: this.getInfo(item.children)
                    };
                } else {
                    return {
                        id: item.value,
                        label: this.$i18n.t(item.label)
                    };
                }
            });
        },
        getData() {
            if(!this.url){
                return
            }
            this.loading = true;
            this.empty = false;
            let data = {
                page: (this.page - 1).toString(),
                size: this.pageSize.toString(),
                search: this.search
            };
            if (this.noPage) {
                delete data.page;
                delete data.size;
            }
            if (this.listQuery) {
                data = { ...data, ...this.listQuery };
            }

            let _routeQuery = {
                ...data,
                ...this.pageQuery
            };

            // if (JSON.stringify(_routeQuery) !== JSON.stringify(this.$route.query)) {
            //     this.$router.replace({
            //         name: this.$route.name,
            //         query: _routeQuery
            //     });
            // }

            const back = () => {
                if (this.httpType === 'post') {
                    return this.$http.post(this.url, data, this.formType);
                } else {
                    return this.$http.get(this.url, data, this.formType);
                }
            };
            back()
                .then(res => {
                    if (res) {
                        if (this.noPage) {
                            this.list = res;
                            this.empty = res.length === 0;
                        } else {
                            if (res.first || !this.pushList) {
                                this.list = res.content;
                            } else {
                                this.list = [...this.list, ...res.content];
                            }
                            this.totalPages = res.totalPages;
                            this.totalElements = res.totalElements;
                            this.empty = res.empty;
                            this.finish = res.last;
                        }
                    } else {
                        this.list = [];
                        this.totalPages = 0;
                        this.totalElements = 0;
                        this.empty = true;
                    }

                    this.loading = false;
                    console.log(res);
                })
                .catch(e => {
                    this.list = [];
                    this.error = true;
                    this.loading = false;
                    this.empty = true;
                });
        },
        changeSort(prop, order) {
            let newOrder = '';
            if (order) {
                if (this.sort[prop] !== order) {
                    newOrder = order;
                } else {
                    newOrder = '';
                }
            } else {
                if (this.sort[prop] === 'asc') {
                    newOrder = 'desc';
                } else if (this.sort[prop] === 'desc') {
                    newOrder = '';
                } else {
                    newOrder = 'asc';
                }
            }
            this.sort = { ...this.sort, [prop]: newOrder };
        },
        onSortChange(e) {
            this.sort = e;
            this.getData();
        },
        onSizeChange(e) {
            window.localStorage.setItem('pageSize', e);
            this.page = 1;
            this.pageSize = e;
            this.getData();
        },
        onCurrentChange(e) {
            window.localStorage.setItem('page', e);
            document.documentElement?.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            this.page = e;
            this.getData();
        },
        getListStr(list = [], tag = ',') {
            return list
                .filter(item => {
                    return item !== 'all';
                })
                .join(',');
        },
        submitSearch() {
            this.$nextTick(() => {
                this.page = 1;
                this.search = this.searchKey;
                this.getData();
            });
        },
        refreash() {}
    },
    watch: {
        sort() {
            this.page = 1;
            this.getData();
        },
        $route() {
            if (this.$route.query.search && this.search !== this.$route.query.search && this.$route.name !== 'search') {
                this.search = this.$route.query.search;
                this.searchKey = this.$route.query.search;
                this.page = 1;
                this.getData();
            }
        }
    },
    components: {
        'radio-group': RadioGroup
    }
};
