<template>
    <div class="logo">
        <auto-img :src="logo" mul="43%"></auto-img>
        <!-- <div class="logoTips" v-if="!userInfo" @click.stop="goLogin">
            {{ $t('deng-lu-ke-kan-pin-pai') }}
        </div> -->
    </div>
</template>
<script>
import { mapState } from 'vuex';
import UserState from '../../mixins/UserState';
export default {
    name: 'LOGO',
    props: {
        logo: {
            type: String,
            default: ''
        }
    },
    mixins: [UserState],
    computed: {
        ...mapState(['userInfo'])
    }
};
</script>
<style lang="scss" scoped>
.logo {
    position: relative;
    overflow: hidden;

    .logoImg {
        filter: blur(4px);
    }

    .logoTips {
        font-size: 12px;
        color: rgba(255, 255, 255, 1);
        line-height: 17px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.26);
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        white-space: nowrap;
    }
}
</style>
