import store from '../store';

const selectList = store.state.areaList;

const countrys = () => {
    return selectList;
};

function getContinent(name) {
    return selectList.find(item => {
        return !!item.children.find(_child => {
            return _child.value === name;
        });
    });
}

// function getInfo(list) {
//     let _json = {};

//     list.forEach(item => {
//         _json[item.label] = item.label;

//         if (item.children) {
//             _json = { ..._json, ...getInfo(item.children) };
//         }
//     });

//     return _json;
// }

export { selectList, countrys, getContinent };
