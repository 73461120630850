<template>
    <div class="radio-group">
        <div
            class="item"
            :class="{ active: checkChoose(item.key, true) }"
            v-for="item in list"
            :key="item.key"
            @click="choose(item.key)"
        >
            {{ $i18n.t(getName(item.name)) }}
            <div class="right" v-if="getList(item.name)">
                <i class="el-icon-caret-top" :class="{ active: checkChoose('TIME_SEQUENCE') }"></i>
                <i class="el-icon-caret-bottom" :class="{ active: checkChoose('TIME') }"></i>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RadioGroup',
    props: {
        list: {
            type: Array,
            default: []
        },
        value: {
            type: String
        }
    },
    methods: {
        choose(val) {
            if (val === 'TIME' && this.value === val) {
                this.$emit('input', 'TIME_SEQUENCE');
            } else {
                this.$emit('input', val);
            }
        },
        getName(info) {
            if (typeof info === 'string') {
                return info;
            } else {
                return info.name;
            }
        },
        getList(info) {
            if (typeof info === 'string') {
                return null;
            } else {
                return info.list;
            }
        },
        checkChoose(chooseValue, isMore = false) {
            if ((!isMore && chooseValue === this.value) || (isMore && this.value.indexOf(chooseValue) !== -1)) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.radio-group {
    display: flex;
}
.item {
    line-height: 16px;
    text-align: center;
    padding: 0 18px;
    font-size: 14px;
    color: #818999;
    cursor: pointer;
    position: relative;
    height: 56px;
    display: flex;
    align-items: center;

    &.active {
        background: rgba(255, 165, 38, 0.06);
        color: #ffa526;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 3px;
            background: #ffa526;
        }
    }

    &:hover {
        color: #ffa526;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 5px;
        i {
            color: #818999;
            line-height: 6px;
						font-size: 10px;

            &.active {
                color: #ffa526;
            }
        }
    }
}
</style>
