export default {
    computed: {
        name() {
            return this.$i18n.locale.indexOf('zh') != -1 ? this.info.chCompanyName : this.info.enCompanyName;
        },
        categories() {
            let str = this.info.categories
                ? [...this.info.categories]
                      .filter((item, index) => {
                          return index === 0 || item.id !== [...this.info.categories][index - 1].id;
                      })
                      .map(item => {
                          return this.$i18n.locale.indexOf('zh') != -1 ? item.chName.split('/').pop() : item.enName;
                      })
                      .join('/')
                : '';

            if (this.info.customCategory) {
                str +=
                    (str ? '/' : '') +
                    this.getName(this.info, ['customCategory', 'enCustomCategory']).split(',').join('/');
            }

            return str;
        },
        country() {
            return this.$t(this.info.headquarter || this.info.country || '中国');
        },
        logo() {
            return this.info.logo ? this.info.logo + '?x-oss-process=image/resize,m_fill,h_480,w_480' : '';
        }
    }
};
