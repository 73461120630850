<template>
    <router-link :to="'/brandDetail?id=' + info.id" :class="{ checked: checked && hasChoose }">
        <el-card :body-style="{ padding: '0px' }" :class="[shadow ? 'shadow' : 'border']">
            <div class="top">
                <div
                    class="img bg"
                    :style="{
                        backgroundImage: `url(${logo})`
                    }"
                ></div>
            </div>
            <div class="info">
                <div class="name" v-html="getImport(name)"></div>
                <div class="sub">
                    <span class="iconfont">&#xe605;</span>
                    <span v-html="getImport(categories)"></span>
                </div>

                <div class="sub">
                    <span class="iconfont">&#xe604;</span>
                    <span>{{ country }}</span>
                </div>

                <el-button
                    type="warning"
                    plain
                    size="mini"
                    v-if="!hasChoose"
                    @click.stop.prevent="advisory(info, 'vendor')"
                >
                    {{ $t('zi-xun-1') }}</el-button
                >
            </div>

            <div class="check" @click.stop.prevent="chooseEvent" v-if="hasChoose">
                <el-checkbox v-model="checked"></el-checkbox>
            </div>
        </el-card>
    </router-link>
</template>
<script>
import brandInfo from '../../mixins/brandInfo';
import UserStoreOperation from '../../mixins/UserStoreOperation';

export default {
    name: 'Gride',
    mixins: [brandInfo, UserStoreOperation],
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        shadow: {
            type: Boolean,
            default: false
        },
        hasChoose: {
            type: Boolean,
            default: false
        },
        chooseList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        search: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            checked: false
        };
    },
    computed: {
        nowChooseList() {
            var _set = new Set([...this.chooseList]);
            if (this.checked) {
                _set.add(this.info.id);
            } else {
                _set.delete(this.info.id);
            }
            return [..._set];
        }
    },
    watch: {
        chooseList() {
            if (this.chooseList !== this.nowChooseList) {
                this.checked = this.chooseList.includes(this.info.id);
            }
        }
    },
    methods: {
        changeCheck() {
            this.$nextTick(() => {
                this.$emit('update:chooseList', this.nowChooseList);
            });
        },
        chooseEvent() {
            let chooseList = [...this.chooseList];
            if (chooseList.includes(this.info.id)) {
                chooseList = chooseList.filter(item => {
                    return item !== this.info.id;
                });
            } else {
                chooseList.push(this.info.id);
            }
            this.$emit('update:chooseList', chooseList);
        }
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';

.check {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .el-checkbox {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    border-width: 0px;

    &.border {
        transform: translate3d(0, 0, 0);
        &:hover {
            box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
            border: 1px solid rgba(255, 165, 38, 1);
            transform: scale(1.1);

            .logo {
                display: none;
            }

            .sub-left {
                display: block;
            }
            .el-button--warning {
                display: block;
            }
        }
    }

    &.shadow {
        border: 1px solid #dcdfe6;
        &:hover {
            // box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
            border: 1px solid rgba(255, 165, 38, 1);
            // transform: scale(1.1);

            // .logo1 {
            //     display: none;
            // }

            // .sub-left {
            //     display: block;
            // }
            // .bottom {
            //     display: block;
            //     &.el-row {
            //         display: flex;
            //     }
            // }
        }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    // padding: 26px;
    .img {
        width: 100%;
        height: 0px;
        padding-top: 100%;
        // background-size: contain;
        background-repeat: no-repeat;
    }
}

.name {
    font-size: 16px;
    color: $--color-text-regular;
    line-height: 26px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 14px 13px;
    position: relative;
}

.sub {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: rgba(86, 91, 102, 1);
    line-height: 24px;
    margin-top: 7px;

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
.iconfont {
    color: #dcdfe6;
    font-size: 20px;
    display: block;
    margin-right: 6px;
    flex-shrink: 0;
}

.el-button--warning.is-plain {
    background-color: transparent;
    padding: 3px 10px;
    padding: 3px 10px;
    position: absolute;
    right: 13px;
    bottom: 15px;

    &:hover {
        background-color: $--color-warning;
    }
}
</style>
